import { Routes } from '@angular/router';


export const appRoutes = {
    login: "dashboard/Login",
    page:"Home",
}

export const routes: Routes = [
    {path:"",redirectTo:'Home',pathMatch:'full',},
     {path:"Home",loadComponent: () => import('./page/page.component').then(c=>c.PageComponent)},
    {path:"**",redirectTo:'Home',pathMatch:'full',},
    ];
      
      