import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Quran';

  constructor(public translateService: TranslateService,
  ) {

   let lang = localStorage.getItem('lang') || 'ar';

   translateService.use(lang);
   const dom: any = document.querySelector('body');

   if (lang === 'ar'||lang === 'fa'||lang === 'zh'||lang === 'ru'||lang === 'ur') {
     dom.classList.add('app_rtl');
   } else {
     dom.classList.remove('app_rtl')


 }
  }}
